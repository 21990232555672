import React from "react"
import styled from "styled-components"

interface NavProps {
  className?: string
  children: React.ReactNode
  isOpen?: boolean
}

const Nav: React.FC<NavProps> = ({ className, children, isOpen }) => {
  return (
    <nav className={`${className} ${isOpen ? 'show' : ''}`}>
      {children}
    </nav>
  )
}

export default styled(Nav)`
  flex-grow:2;
  background:rgba(0, 0, 0, 0.9);
  width:100%;
  position:fixed;
  top:0;
  left:0;
  height:100%;
  max-width:400px;
  z-index:1005;
  @media screen and (min-width:401px) {
    max-width: 300px;
  }
  transform: translateX(-100%);
  transition: transform .3s ease;
  &.show {
    visibility: visible;
    transform: translateX(0%);
  }
  @media screen and (min-width:900px) {
    visibility:visible;
    width:initial;
    position:relative;
    background:transparent;
    transform:translateX(0%);
    margin-left:auto;
    max-width:100%;
    top:0;
  }
  @media screen and (min-width:900px) and (max-width:1050px) {
    width:100%;
  }
`
