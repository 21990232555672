import React from "react"
import styled from "styled-components"
import NavLink from "./navLink"

interface SubMenuProps {
  className?: string
  children: React.ReactNode
  isOpen?: boolean
  ref:React.Ref<HTMLDivElement>
  style?: React.CSSProperties
}

const SubMenu: React.FC<SubMenuProps> = React.forwardRef<HTMLDivElement, SubMenuProps>(({ style, className, children, isOpen }, ref) => {
  return (
    <div style={style} ref={ref} className={className}>
      <ul>
        {children}
      </ul>
    </div>
  )
})

export default styled(SubMenu)`
  //display:none;
  height: 0;
  overflow: hidden;
  transition: all .3s ease;
  @media screen and (min-width:900px) {
    position: absolute;
    background:rgba(0, 0, 0, 0.9);
    top:100%;
    min-width:250px;
  }
  ul {
    width:100%;
  }
  a {
    color:white;
    padding:20px 0;
    &:after, &:before {
      display:none;
    }
  }
  ${NavLink} {
    a {
      color:white;
      padding:20px 0;
      &:after, &:before {
        display:none;
      }
    }
  }
  padding-left:0;
`
