import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import "../../scss/mixins.scss"

interface HeaderLogoProps {
  className?: string
  page?: string
}

const HeaderLogo: React.FC<HeaderLogoProps> = ({ className, page }) => {
  return (
    <div className={className}>
      <h1 className="site_logo"><Link to="/">Casa <span className="site_logo__divider"> | </span> Italia</Link>
      </h1>
    </div>
  )
}

export default styled(HeaderLogo)`
  max-width:195px;
  color:${({page}) => page && page === '/' ? 'white' : 'black' };;
  h1 {
    @media screen and (min-width:900px) {
      // color:black;
    }
  }
  @media screen and (min-width:900px) {
    margin: 10px auto auto;
  }
  .site_logo {
    //@include fluid-type(300px, 1200px, 15px, 18px);
    font-size: calc(15px + (18 - 15) * ((100vw - 300px) / (1200 - 300)));
    text-transform: uppercase;
    text-align: center;
    margin-top:0;
    a {
      text-decoration: none;
      color:inherit;
    }
    &__divider {
      // display:inline-block;
      // position: relative;
      // top:5px;
      font-size:30px;
      line-height: 30px;
      // @include fluid-type(300px, 1200px, 25px, 40px);
    }
  }
`
