import React, { useRef, useState, useEffect } from "react"
import styled from "styled-components"
import { FaCaretDown } from "react-icons/fa"
import { Link } from "gatsby"
import { useMediaQuery } from "react-responsive"

interface NavLinkProps {
  className?: string
  children?: React.ReactNode
  isDesktop: boolean,
  label: string
  link: string
  height?: string
  page?: string
  subMenu?: {
    label: string
    link: string
  }[] | boolean | React.ReactElement
}

const NavLink: React.FC<NavLinkProps> = ({ className, isDesktop, children, label, link, subMenu, height,page  }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [linkColor, setLinkColor] = useState("white")
  const [firstLoad, setFirstLoad] = useState(false)
  useEffect(() => {
    if(!firstLoad) {
      setFirstLoad(true)
    }
    setLinkColor((page && page === '/') || (page === '/*' && !isDesktop ) ? 'white' : (isDesktop ? 'black' : 'white'))
  }, [firstLoad, isDesktop])


  return (
    <li onClick={(e) => {e.preventDefault(); !isDesktop && setIsOpen(!isOpen)}} onMouseLeave={() => { subMenu && isDesktop && setIsOpen(false)}} onMouseEnter={() => { subMenu && isDesktop && setIsOpen(true)}} className={className}>
      <Link style={{color: linkColor}} className={ page !== undefined && page?.indexOf(link) !== -1 ? 'active' : 'regular'} to={link}>{label} {subMenu && <FaCaretDown />}</Link>
      {/*{children}*/}
      { React.Children.map(children, child => {

          return React.cloneElement(child as React.ReactElement,  { style: {
            height: isOpen ? height  : "0px"
            }
          })

      })}
    </li>
  )
}

export default styled(NavLink)`
  list-style-type: none;
  padding:0 10px;
  font-size:14px;
  ul {
    li {
      a {
        color:white !important;
      }
    }
  }
  a {
    font-family: "Bankgothic Regular";
    color:${({page, isDesktop}) => (page && page === '/') || (page === '/*' && !isDesktop ) ? 'white' : (isDesktop ? 'black' : 'white')   };
    font-size:11px;
    position:relative;
    text-transform: initial;
    text-decoration:none;
    display: block;
    padding:20px 0;
    &.active{
      color:red !important;
    }
    @media screen and (min-width:900px) {
      padding:10px 0;
      // color:black;
      &:after {
        display:block;
        content: '';
        top:100%;
        left:50%;
        position: absolute;
        height:2px;
        // background:black;
        background: ${({page}) => page && page === '/' ? 'white' : 'black' };;
        width:0;
        transition: all .3s ease
      }
      &:before {
        display:block;
        content: '';
        position: absolute;
        top:100%;
        right:50%;
        height:2px;
        // background:black;
        background: ${({page}) => page && page === '/' ? 'white' : 'black' };;
        width:0;
        transition: all .3s ease
      }
      &:hover {
        &:before, &:after {
          width:50%;
        }
      }
    }
  }
`
