import React from "react"
import styled from "styled-components"

interface MainMenuProps {
  className?: string
  children: React.ReactNode
}

const MainMenu: React.FC<MainMenuProps> = ({ className, children }) => {
  return (
    <ul className={className}>
      {children}
    </ul>
  )
}

export default styled(MainMenu)`
  display:flex;
  flex-direction:column;
  padding-left:10px;
  margin-top:12px;
  @media screen and (min-width:900px) {
    flex-direction:row;
    justify-content: space-around;

  }
`
