import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

interface MenuLogoProps {
  className?: string
}

const MenuLogo:React.FC<MenuLogoProps> = ({ className }) => {
  return (
    <div className={`${className} hide-on-desktop`}>
      <h1 className="site_logo"><Link to="/">Casa <span className="site_logo__divider"> | </span> Italia</Link>
      </h1>
    </div>
  )
}

export default styled(MenuLogo)`
  padding:20px 10px 0 10px;
  h1 {
    text-transform: uppercase;
    text-align: left;
    color:white;
    a {
      color:white;
      text-decoration:none;
    }
  }
`
