import React from "react"
import styled from "styled-components"

interface NavWrapperProps {
  className?: string
  children: React.ReactNode
}

const NavWrapper: React.FC<NavWrapperProps> = ({ className, children }) => {
  return (
    <div className={className}>
      {children}
    </div>
  )
}

export default styled(NavWrapper)`
  position: relative;
  @media screen and (min-width:900px) {
    position:relative;
  }
  display:flex;
  flex-wrap:wrap;
  width:90%;
  margin:auto auto auto 5%;
  // padding-top:10px;
  @media screen and (min-width:900px) {
    padding-top:0px;
  }
`
