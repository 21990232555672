import React from "react"
import styled from "styled-components"

interface HamburgerProps {
  className?: string
  isOpen?: boolean
  toggleMenu: Function
}

const Hamburger: React.FC<HamburgerProps> = ({ className, toggleMenu, isOpen }) => {

  return (
    <div onClick={(e) => { toggleMenu(!isOpen) }} className={`${className} hide-on-desktop ${isOpen ? ' open' : null}`}>
      <span />
      <span />
      <span />
  </div>
  )
}

export default styled(Hamburger)`
    position: absolute;
    right:10px;
    display:flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    width: 40px;
    height: 30px;
    z-index:1010;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
    &.open {
      cursor: pointer;
      span {
        &:nth-child(1){
          -webkit-transform: rotate(45deg);
          -moz-transform: rotate(45deg);
          -o-transform: rotate(45deg);
          transform: rotate(45deg);
          top: 1px;
          left: 8px;
        }
        &:nth-child(2){
          width: 0%;
          opacity: 0;
        }
        &:nth-child(3){
          -webkit-transform: rotate(-45deg);
          -moz-transform: rotate(-45deg);
          -o-transform: rotate(-45deg);
          transform: rotate(-45deg);
          top: 29px;
          left: 8px;
        }
      }
    }
    span {
      display: block;
      position: absolute;
      height: 6px;
      width: 100%;
      //background: #d3531a;
      background:red;
      border-radius: 9px;
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: .25s ease-in-out;
      -moz-transition: .25s ease-in-out;
      -o-transition: .25s ease-in-out;
      transition: .25s ease-in-out;
      &:nth-child(1) {
        top: 0px;
        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;
      }
      &:nth-child(2) {
        top: 12px;
        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;
      }
      &:nth-child(3) {
        top: 24px;
        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;
      }
    }
`
