/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import { graphql, useStaticQuery } from "gatsby"
// @ts-ignore
import GlobalContext from "./GlobalProvider"
import React, { ReactNode, useContext, useState } from "react"
import Header from "./header"
import Footer from "./footer"
import Seo from "./seo"
// import "./layout.css"
import "../scss/global.scss"
import { SiteTitleQuery } from "../../graphql-types"
// import { useSticky } from "../hooks"
import { useMediaQuery } from "react-responsive"

interface Props {
  children: ReactNode
  page?: string
  lang: string
  empty?: boolean
}

const useSiteTitle = () => {
  const data  = useStaticQuery<SiteTitleQuery>(
    graphql`
      query SiteTitle {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  )
  return data?.site?.siteMetadata?.title || ""
}

const Layout: React.FC<Props> = ({ children, page, lang, empty }) => {
  const globalContext = useContext(GlobalContext)
  const title = useSiteTitle()
  const [stickyOffset , setStickyOffset] = useState({isSticky: false, offset:0});
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 900px)'
  })
  return (
    !empty && <div style={{marginTop: isDesktopOrLaptop ? stickyOffset.offset : '', overflow: globalContext.isVideoPlaying ? "hidden" : "initial", height:globalContext.isVideoPlaying ? "100vh" : "initial" }}>
      <Seo title="Home Page" />
      <Header lang={lang} page={page} siteTitle={title} setOffset={setStickyOffset} />
      <div>
        <main>{children}</main>
      </div>
      <Footer />
    </div> || <div style={{ height:"100vh", display:"flex", flexDirection: "column", justifyContent: "space-between"}}>
      <Seo title="Home Page" />
      <Header lang={lang} page={page} siteTitle={title} setOffset={setStickyOffset} />
      <div>
        <main>{children}</main>
      </div>
      <Footer />
    </div>
  )
}

export default Layout
