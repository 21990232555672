
import React, { useRef, useState, useEffect, forwardRef } from "react"
import styled from "styled-components"
import Hamburger from "./Hamburger"
import HeaderLogo from "./Logo/headerLogo"
import MenuLogo from "./Logo/menuLogo"
import Nav from "./NavigationMenu/nav"
import NavWrapper from "./NavigationMenu/navWrapper"
import MainMenu from "./NavigationMenu/mainMenu"
import NavLink from "./NavigationMenu/navLink"
import SubMenu from "./NavigationMenu/subMenu"
import { useMediaQuery } from "react-responsive"
import { useStaticQuery, graphql } from "gatsby"


interface Props {
  className?: string
  siteTitle?: string
  lang: string
  page?: string
  sticky?: boolean
  setOffset:  React.Dispatch<React.SetStateAction<{isSticky: boolean, offset: number}>>
}

const Header: React.FC<Props> = ({ className, setOffset, siteTitle, page, lang }, ref) => {

  const data = useStaticQuery(graphql`
    {
        allTemplate {
        nodes {
          name
          locales {
            iso
            value
          }
        }
      }
      allPartner {
        nodes {
          uid
          locales {
            iso
            value
          }
          organisationName
        }
      }
    }
  `)
  const parentRef = useRef<HTMLDivElement>(null)
  const [isOpenMenu, setIsOpenMenu] = useState(false)
  const [sticky, setSticky] = useState({ isSticky: false, offset: 0 });
  const headerRef = useRef<HTMLHeadingElement>(null);
  const [subNav, setSubNav] = useState<HTMLDivElement | null>(null)
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 900px)'
  })
  const handleScroll = (elTopOffset:number, elHeight:number) => {
    if (window.pageYOffset > (elTopOffset + elHeight)) {
      setSticky({ isSticky: true, offset: elHeight });
      setOffset({ isSticky: true, offset: elHeight })
    } else {
      setSticky({ isSticky: false, offset: 0 });
      setOffset( {isSticky: false, offset: 0 })
    }
  };
  useEffect(() => {
    setSubNav(parentRef.current);
  },[])
  // console.log(data.allTemplate)
  useEffect(() => {
    var header = headerRef?.current?.getBoundingClientRect();
    const handleScrollEvent = () => {
      if(header) {
        handleScroll(header.top, header.height)
      }
    }

    window.addEventListener('scroll', handleScrollEvent);

    return () => {
      window.removeEventListener('scroll', handleScrollEvent);
    };
  }, []);

  return (
    <header ref={headerRef} className={`${className} header${sticky.isSticky && isDesktopOrLaptop ? ' sticky' : ''}`}>
    <NavWrapper>
      <HeaderLogo page={page} />
      <Hamburger toggleMenu={setIsOpenMenu} isOpen={isOpenMenu} />
      <Nav isOpen={isOpenMenu}>
        <MenuLogo />
        <MainMenu>
          <NavLink isDesktop={isDesktopOrLaptop} page={page} label="About Us" link={data.allTemplate.nodes[0].locales.find(l => l.iso === lang).value} />
          <NavLink isDesktop={isDesktopOrLaptop} page={page} height={subNav ? subNav.scrollHeight + "px" : "0px"} label="Partner Brands" link="#" subMenu>
            <SubMenu ref={parentRef}>
              {data.allPartner && data.allPartner.nodes && data.allPartner.nodes.map(n => {
                const localParentModuleText = n.locales.find(l => l.iso === lang)
                return <NavLink key={n.uid} isDesktop={isDesktopOrLaptop} label={n.organisationName} link={localParentModuleText?.value} />
              })}
              {/*<NavLink isDesktop={isDesktopOrLaptop} label="Fimes" link="/brands/fimes" />*/}
              {/*<NavLink isDesktop={isDesktopOrLaptop} label="Mussi" link="/brands/mussi" />*/}
              {/*<NavLink isDesktop={isDesktopOrLaptop} label="Mk Kitchens" link="/brands/mk" />*/}
            </SubMenu>
          </NavLink>
          <NavLink isDesktop={isDesktopOrLaptop} page={page} label="Projects" link={data.allTemplate.nodes[2].locales.find(l => l.iso === lang).value} />
          <NavLink isDesktop={isDesktopOrLaptop} page={page} label="Design Studio" link="/design" />
          <NavLink isDesktop={isDesktopOrLaptop} page={page} label="Press" link={data.allTemplate.nodes[4].locales.find(l => l.iso === lang).value} />
          {/*<NavLink isDesktop={isDesktopOrLaptop} page={page} label="Blog" link={data.allTemplate.nodes[3].locales.find(l => l.iso === lang).value} />*/}
          <NavLink isDesktop={isDesktopOrLaptop} page={page} label="Media" link="/blog/media" />
          <NavLink isDesktop={isDesktopOrLaptop} page={page} label="Contact" link="/contact" />
        </MainMenu>
      </Nav>
    </NavWrapper>
  </header>)
}

Header.defaultProps = {
  siteTitle: ``,
}

export default styled(Header)`
  background-color: black;
  padding-top:8px;
  top: 0;
  position: sticky;
  z-index:1000;
  .site_logo {
    color:white;
  }
  // position:${props => props.sticky ? "fixed" : "relative"};
  @media screen and (min-width:900px) {
    background-color: ${({page}) => (page && page === '/') || page === '/*'  ? 'black' : 'white'};
    padding-top:0;
    position: initial;
    .site_logo {
      color: ${({page}) => (page && page === '/') || page === '/*' ? 'white' : 'black'}
    }
  }
`
