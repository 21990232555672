import React from "react"
import styled from "styled-components"
import { graphql, Link, useStaticQuery } from "gatsby"

interface IFooterProps {
  className?: string
  page?: string
}

const Footer: React.FC<IFooterProps> = ({ className, page }) => {
  const lang = "en"
  const data = useStaticQuery(graphql`
    {
        allTemplate {
        nodes {
          name
          locales {
            iso
            value
          }
        }
      }
        department(name: {eq: "Main"}) {
            contacts {
                type
                value
            }
            location {
                address1
                address2
                city
                postalCode
            }
        }
    }
  `)

  const emails = data.department.contacts.filter(c => c.type === "EMAIL")
  const phones = data.department.contacts.filter(c => c.type === "PHONE_PLAIN")
  const address1 = data.department.location.address1
  const address2 = data.department.location.address2
  const city = data.department.location.city
  const postalCode = data.department.location.postalCode
  return (
    <footer className={`${className} footer`}>
      <div className="footer__content">
        <div className="footer__box footer__contact-info">
          <h3 className="site_logo">Casa <span className="site_logo__divider"> | </span> Italia</h3>
          <p><i className="fas fa-envelope" /> <a href={`mailto:${emails[2].value}`}> {emails[2].value}</a>
          </p>
          <p><i className="fas fa-phone-alt" /> <a href={`tel:${phones[0].value}`}> {phones[0].value}</a></p>
          <p><i className="fas fa-envelope" /> <a href={`mailto:${emails[0].value}`}> {emails[0].value} </a>
          </p>
          <p><i className="fas fa-phone-alt" /> <a href={`tel:${phones[1].value}`}> {phones[1].value}</a></p>
          <p><a style={{color: 'white', margin:0}} href="https://instagram.com/casaitaliausa?igshid=NzZlODBkYWE4Ng=="><i style={{fontSize:"16px", display: 'inline-block', marginRight: "6px"}}
            className="fab fa-instagram" /> find us on instagram</a></p>
        </div>
        <div className="footer__box footer__contact-location">
          <p style={{display:"flex"}}><i className="fas fa-map-marker-alt" /> <span
            style={{display: "inline-block", marginLeft:"10px"}}>Casa Italia<br />
            {`${address1}, ${address2}`} <br/>
            {`${city}, ${postalCode}`}
          </span></p>
          <p style={{display:"flex"}}><i className="fas fa-map-marker-alt" /> <span
            style={{marginLeft:"10px", display:"flex"}}>Milan office <br />
            Via Copernico 22, <br />
            20125 Milano MI, Italy</span></p>
        </div>
        <div className="footer__box footer__navmenu">
          <ul>
            <li><Link className={page === '/about' ? 'active' : ''} to={data.allTemplate.nodes[0].locales.find(l => l.iso === lang).value}>About Us</Link></li>
            <li><Link className={page === '/projects' ? 'active' : ''} to={data.allTemplate.nodes[3].locales.find(l => l.iso === lang).value}>Projects</Link>
            </li>
            <li><Link className={page === '/design' ? 'active' : ''} to="/design">Design Studio</Link>
            </li>
            <li><Link className={page === '/press' ? 'active' : ''} to={data.allTemplate.nodes[4].locales.find(l => l.iso === lang).value}>Press</Link></li>
            <li><Link className={page === '/blog/media' ? 'active' : ''} to="/blog/media">Media</Link></li>
            <li><Link className={page === '/contact' ? 'active' : ''} to="/contact">Contact</Link></li>
            <li><Link className={page === '/privacy-policy' ? 'active' : ''} to="/privacy-policy">Privacy
              Policy</Link></li>
          </ul>
        </div>
      </div>
      <div className="copyright">
        <p className="text">All right reserved Casa Italia {new Date().getFullYear()} &copy;</p>
        <p className="gf">Powered By <a target="_blank" href="https://greenflamingobcn.com">Green Flamingo</a></p>
      </div>
    </footer>
  )
}

export default styled(Footer)`
  color:white;
  background-color:black;
  font-size:15px;
  // display:flex;
  // flex-direction: column;
  // @media screen and (min-width:450px) {
  //     flex-direction: row;
  // }
  font-family: Bankgothic Regular,serif;
  .fa, .fas, .far, .fal, .fab {
    margin-right:0;
    line-height:22px;
    font-size:13px;
  }
  p{
    font-size: 11px !important;
    line-height:20px;
  }
  .site_logo {
    text-transform: uppercase;
    font-size:24px;
    margin-top:0;
  }
  .footer__box {
    width:100%;
    display:flex;
    flex-shrink:0;
    justify-content: center;
    flex-wrap:wrap;
    margin-bottom:20px;
    @media screen and (min-width:768px) {
      width:30%;
    }
    p {
      display:flex;
      width:100%;
      flex-shrink: 0;
      justify-content: flex-start;
    }
  }
  .footer__content {
    padding:25px 5% 20px;
    flex-wrap:wrap;
    display:flex;
    flex-direction: column;
    width:100%;
    max-width:1200px;
    margin:auto;
    @media screen and (min-width:450px) {
      flex-direction: row;
    }
    a {
      transition: all .3s ease;
      &:hover {
        cursor: pointer;
        color: red;
      }
    }
  }
  .footer__contact-location {
    flex-grow:2;
  }
  .footer__contact-info {
    display:flex;
    flex-grow:2;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom:20px;
    p {
      margin:7px 0;
    }
    a {
      color : white;
      text-decoration: none;
      margin-left:10px;
    }
  }
  .footer__navmenu {
    display:flex;
    flex-direction: column;
    flex-grow:1;
    align-items: flex-start;

    @media screen and (min-width:768px) {
      align-items: center;
    }
    ul {
      padding:0;
      list-style-type: none;
      @media screen and (min-width:1061px){
        // display:flex;
        justify-content: flex-end;
      }
    }
    li {
      //font-size:14px;
      a {
        display:block;
        color:white;
        text-decoration: none;
        padding:6px;
        font-size: 11px !important;
      }
    }
  }
  .copyright {
    text-align: center;
    background:#f1f1f1;
    color:black;
    padding:5px;
    font-size:10px;
    text-transform: uppercase;
    .text {
      margin-bottom:0;
    }
    p {
      font-size:inherit !important;
    }
    .gf {
      font-size: 9px !important;
      margin-top: 3px;
      a {
        color : red;
      }
    }
  }
`
